import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },{
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'conti',
    loadChildren: () => import('./conti/conti.module').then( m => m.ContiPageModule)
  },
  // {
  //   path: 'nuovo',
  //   loadChildren: () => import('./nuovo/nuovo.module').then( m => m.NuovoPageModule)
  // },
  {
    path: 'ricorrenze',
    loadChildren: () => import('./ricorrenze/ricorrenze.module').then( m => m.RicorrenzePageModule)
  },
  {
    path: 'transazioni',
    loadChildren: () => import('./transazioni/transazioni.module').then( m => m.TransazioniPageModule)
  },
  {
    path: 'programmate',
    loadChildren: () => import('./programmate/programmate.module').then( m => m.ProgrammatePageModule)
  },
  {
    path: 'ricorrenza',
    loadChildren: () => import('./ricorrenza/ricorrenza.module').then( m => m.RicorrenzaPageModule)
  },
  {
    path: 'pin',
    loadChildren: () => import('./pin/pin.module').then( m => m.PINPageModule)
  },
  {
    path: 'nuovo-giroconto',
    loadChildren: () => import('./nuovo-giroconto/nuovo-giroconto.module').then( m => m.NuovoGirocontoPageModule)
  },
  {
    path: 'transazione',
    loadChildren: () => import('./transazione/transazione.module').then( m => m.TransazionePageModule)
  },
  {
    path: 'nuovo-entrata',
    loadChildren: () => import('./nuovo-entrata/nuovo-entrata.module').then( m => m.NuovoEntrataPageModule)
  },
  {
    path: 'nuovo-uscita',
    loadChildren: () => import('./nuovo-uscita/nuovo-uscita.module').then( m => m.NuovoUscitaPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'obiettivi',
    loadChildren: () => import('./obiettivi/obiettivi.module').then( m => m.ObiettiviPageModule)
  },
  {
    path: 'obiettivo',
    loadChildren: () => import('./obiettivo/obiettivo.module').then( m => m.ObiettivoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./registrazione/registrazione.module').then( m => m.RegistrazionePageModule)
  },
  {
    path: 'setup-iniziale',
    loadChildren: () => import('./setup-iniziale/setup-iniziale.module').then( m => m.SetupInizialePageModule)
  },
  {
    path: 'transazioni-filtri',
    loadChildren: () => import('./transazioni-filtri/transazioni-filtri.module').then( m => m.TransazioniFiltriPageModule)
  },
  {
    path: 'network',
    loadChildren: () => import('./network/network.module').then( m => m.NetworkPageModule)
  },
  {
    path: 'programmata',
    loadChildren: () => import('./programmata/programmata.module').then( m => m.ProgrammataPageModule)
  },
  {
    path: 'nordigen',
    loadChildren: () => import('./nordigen/nordigen.module').then( m => m.NordigenPageModule)
  },
  {
    path: 'recupero-password',
    loadChildren: () => import('./recupero-password/recupero-password.module').then( m => m.RecuperoPasswordPageModule)
  },
  {
    path: 'categorie',
    loadChildren: () => import('./categorie/categorie.module').then( m => m.CategoriePageModule)
  },
  {
    path: 'categoria',
    loadChildren: () => import('./categoria/categoria.module').then( m => m.CategoriaPageModule)
  },
  {
    path: 'notifiche',
    loadChildren: () => import('./notifiche/notifiche.module').then( m => m.NotifichePageModule)
  },
  {
    path: 'notifica',
    loadChildren: () => import('./notifica/notifica.module').then( m => m.NotificaPageModule)
  },
  {
    path: 'informazioni',
    loadChildren: () => import('./informazioni/informazioni.module').then( m => m.InformazioniPageModule)
  },  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'termini-condizioni',
    loadChildren: () => import('./termini-condizioni/termini-condizioni.module').then( m => m.TerminiCondizioniPageModule)
  },
  {
    path: 'limiti-categorie',
    loadChildren: () => import('./limiti-categorie/limiti-categorie.module').then( m => m.LimitiCategoriePageModule)
  },
  {
    path: 'limite-categoria',
    loadChildren: () => import('./limite-categoria/limite-categoria.module').then( m => m.LimiteCategoriaPageModule)
  }





];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
